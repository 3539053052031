import { ConnectionService } from './connection.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DistributorsService } from './distributors.service';
import { IssuersService } from './issuers.service';
import { Issuer } from '../model/issuer';
import { Contact } from '../model/contact';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  constructor(
    private apiService: ApiService,
    private distributorsService: DistributorsService,
    private issuersService: IssuersService,
    private connectionService: ConnectionService
  ) {}

  async sendEmailsToInspire(emailSubject: string, emailBody: string) {
    return await this.apiService
      .post<any, any>(`ddq/ses/internal`, {
        emailSubject,
        emailBody,
      })
      .toPromise();
  }

  // this meant to used as dev testing purpose without proper template setup
  async sendEmailsWithoutTemplate(recipientEmailAddress: string, emailSubject: string, emailBody: string) {
    return await this.apiService
      .post<any, any>(`ddq/ses/test`, {
        recipient: recipientEmailAddress,
        emailSubject,
        emailBody,
      })
      .toPromise();
  }

  async sendEmailsToDistributorContacts(distributorID, templateID, extraMetadata?) {
    return new Promise(async (resolve) => {
      await this.distributorsService
        .getDistributorContactsByDistributor(distributorID)
        .toPromise()
        .then(async (contacts: Contact[]) => {
          const sentEmailPromises = contacts.map((contact) => {
            return new Promise((innerResolve) => {
              try {
                if (!contact.invitesentat) {
                  innerResolve(false);
                } else {
                  const recipient = contact.email;
                  const contactFirstName = contact.name.split(' ')[0];

                  const clientMetadata = {
                    'contact.firstname': contactFirstName,
                    DISTRIBUTOR_NAME: extraMetadata?.distributorName ? extraMetadata.distributorName : null,
                    FORM_TEMPLATE: extraMetadata?.formTemplate ? extraMetadata.formTemplate : null,
                    CURRENT_YEAR: new Date().getFullYear().toString(),
                  };

                  this.apiService
                    .post<any, any>('ddq/ses/external', {
                      recipient,
                      clientMetadata,
                      templateID,
                    })
                    .subscribe(
                      (res) => {
                        console.log(res);
                        innerResolve(true);
                      },
                      (e) => {
                        console.log(e);
                        innerResolve(false);
                      }
                    );
                }
              } catch (e) {
                console.log(e);
                innerResolve(false);
              }
            });
          });
          await Promise.all(sentEmailPromises);
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  async sendEmailsToDistributorContactsWithIssuerInfo(distributorID, templateID, issuerID, extraMetadata?) {
    const connectionID = extraMetadata?.connectionId || null;
    return new Promise(async (resolve) => {
      await this.distributorsService
        .getDistributorContactsByDistributor(distributorID)
        .toPromise()
        .then(async (contacts: Contact[]) => {
          await this.issuersService
            .getIssuer(issuerID)
            .toPromise()
            .then(async (issuer: Issuer) => {
              let expDate;
              if (templateID === 'RenewNotificationEmailForDistributor' && connectionID) {
                expDate = (await this.connectionService.getExpDate(connectionID).toPromise()).slice(0, 10);
              }

              const sentEmailPromises = contacts.map((contact) => {
                return new Promise(async (innerResolve) => {
                  try {
                    if (!contact.invitesentat) {
                      innerResolve(false);
                    } else {
                      const recipient = contact.email;
                      const contactFirstName = contact.name.split(' ')[0];
                      const issuerName = issuer.name;

                      const clientMetadata = {
                        'contact.firstname': contactFirstName,
                        ISSUER: issuerName,
                        FORM_TEMPLATE: extraMetadata?.formTemplate ? extraMetadata.formTemplate : null,
                        DISTRIBUTOR_NAME: extraMetadata?.distributorName ? extraMetadata.distributorName : null,
                        EXPIRY_DATE: expDate ? expDate : null,
                        DISTRIBUTOR_ID: extraMetadata?.distributorId ? extraMetadata.distributorId : distributorID,
                        CURRENT_YEAR: new Date().getFullYear().toString(),
                      };

                      await this.apiService
                        .post<any, any>('ddq/ses/external', {
                          recipient,
                          clientMetadata,
                          templateID,
                        })
                        .toPromise()
                        .then((res) => {
                          console.log(res);
                          innerResolve(true);
                        })
                        .catch((e) => {
                          console.log(e);
                          innerResolve(false);
                        });
                    }
                  } catch (e) {
                    console.log(e);
                    innerResolve(false);
                  }
                });
              });
              await Promise.all(sentEmailPromises);
              resolve(true);
            })
            .catch((e) => {
              console.log(e);
              resolve(false);
            });
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  async sendEmailsToIssuerContact(issuerSalesContactIdOrEmail, templateID, extraMetadata: any = null) {
    const issuerContact: any = issuerSalesContactIdOrEmail.includes('@')
      ? await this.issuersService.getIssuerContactByEmail(issuerSalesContactIdOrEmail).toPromise()
      : await this.issuersService.getIssuerSalesContact(issuerSalesContactIdOrEmail).toPromise();

    const recipient = issuerContact.email;
    const salesContactFirstName = issuerContact.name.split(' ')[0];
    const issuerName = (await this.issuersService.getIssuer(issuerContact.issuerid).toPromise()).name;

    const clientMetadata = {
      'contact.firstname': salesContactFirstName,
      ISSUER: issuerName,
      DISTRIBUTOR_LIST: extraMetadata?.distributorList ? extraMetadata.distributorList : 'No Distributor Found',
      KSD_DISTRIBUTOR_LIST: extraMetadata?.ksdDistributorList
        ? extraMetadata.ksdDistributorList
        : 'No Distributor Found',
      CURRENT_YEAR: new Date().getFullYear().toString(),
    };

    await this.apiService
      .post<any, any>('ddq/ses/external', {
        recipient,
        clientMetadata,
        templateID,
      })
      .toPromise()
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async sendEmailsToIssuerContactWithDistributorInfo(
    issuerSalesContactIdOrEmail,
    templateID,
    distributorID,
    extraMetadata: any = null
  ) {
    const issuerContact: any = issuerSalesContactIdOrEmail.includes('@')
      ? await this.issuersService.getIssuerContactByEmail(issuerSalesContactIdOrEmail).toPromise()
      : await this.issuersService.getIssuerSalesContact(issuerSalesContactIdOrEmail).toPromise();

    const recipient = issuerContact.email;
    const salesContactFirstName = issuerContact.name.split(' ')[0];
    const issuerName = (await this.issuersService.getIssuer(issuerContact.issuerid).toPromise()).name;
    const distributorInfo = await this.distributorsService.getDistributor(distributorID).toPromise();
    const distributorName = distributorInfo.name;

    const clientMetadata = {
      'contact.firstname': salesContactFirstName,
      ISSUER: issuerName,
      DISTRIBUTOR_NAME: extraMetadata?.distributorName ? extraMetadata.distributorName : distributorName,
      DISTRIBUTOR_LIST: extraMetadata?.distributorList ? extraMetadata.distributorList : 'No Distributor Found',
      REQUEST_ID: extraMetadata?.requestId ? extraMetadata.requestId : null,
      REQUEST_TYPE: extraMetadata?.requestType ? extraMetadata.requestType : null,
      EXPIRY_DATE: extraMetadata?.expiryDate ? extraMetadata.expiryDate : null,
      FORM_TEMPLATE: extraMetadata?.formTemplate ? extraMetadata.formTemplate : null,
      CURRENT_YEAR: new Date().getFullYear().toString(),
    };

    await this.apiService
      .post<any, any>('ddq/ses/external', {
        recipient,
        clientMetadata,
        templateID,
      })
      .toPromise()
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async sendExternalRequestEmails(request, templateID, extraMetadata?) {
    return new Promise((resolve) => {
      this.distributorsService.getDistributorContactsByDistributor(request.details.distributor.name).subscribe(
        async (contacts: [Contact]) => {
          const sentEmailPromises = contacts.map((contact) => {
            return new Promise((innerResolve) => {
              try {
                if (!contact.invitesentat) {
                  innerResolve(false);
                } else {
                  const recipient = contact.email;
                  const contactFirstName = contact.name.split(' ')[0];

                  const clientMetadata = {
                    'contact.firstname': contactFirstName,
                    DISTRIBUTOR_NAME: extraMetadata?.distributorName ? extraMetadata.distributorName : null,
                    FORM_TEMPLATE: extraMetadata?.formTemplate ? extraMetadata.formTemplate : null,
                    REQUEST_ID: request.id || null,
                    CURRENT_YEAR: new Date().getFullYear().toString(),
                  };

                  this.apiService
                    .post<any, any>('ddq/ses/external', {
                      recipient,
                      clientMetadata,
                      templateID,
                    })
                    .subscribe(
                      (res) => {
                        console.log(res);
                        innerResolve(true);
                      },
                      (e) => {
                        console.log(e);
                        innerResolve(false);
                      }
                    );
                }
              } catch (e) {
                console.log(e);
                innerResolve(false);
              }
            });
          });

          await Promise.all(sentEmailPromises);
          resolve(true);
        },
        (e) => {
          console.log(e);
          resolve(false);
        }
      );
    });
  }

  async sendNotifyEmail(request, distributorName) {
    await this.sendExternalRequestEmails(request, 'NewCommentsTemplate', {
      distributorName,
      formTemplate: request.details.template?.name,
    });
  }

  async sendDDQReturnedEmail(request) {
    this.distributorsService.getDistributor(request.details.distributor.name).subscribe(async (distributor) => {
      const distributorName = distributor.name;
      await this.sendExternalRequestEmails(request, 'ReturnedToDistributor', {
        distributorName,
        formTemplate: request.details.template?.name,
      });
    });
  }

  async sendRequiredSignoffEmail(request) {
    this.distributorsService.getDistributor(request.details.distributor.name).subscribe(async (distributor) => {
      const distributorName = distributor.name;
      await this.sendExternalRequestEmails(request, 'RequiredSignOff', {
        distributorName,
        formTemplate: request.details.template?.name,
      });
    });
  }

  async sendReopenEmail(request) {
    this.distributorsService.getDistributor(request.details.distributor.name).subscribe(async (distributor) => {
      const distributorName = distributor.name;
      await this.sendExternalRequestEmails(request, 'ReopenedDDQ', {
        distributorName,
        formTemplate: request.details.template?.name,
      });
    });
  }

  async sendPublishedEmail(request) {
    this.distributorsService.getDistributor(request.details.distributor.name).subscribe(async (distributor) => {
      const distributorName = distributor.name;
      await this.sendExternalRequestEmails(request, 'PublishedDDQ', {
        distributorName,
        formTemplate: request.details.template?.name,
      });
    });
  }

  getMailingList(salesContactId) {
    return this.apiService.get<any>(`ddq/mailing-list/${salesContactId}`);
  }
}
